import { Input, Spin } from 'antd';
import { useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useMount } from 'ahooks';
import { useSearchParams } from 'react-router-dom';
import style from './convert.module.css';

export default function Convert() {
	const [searchParams, setSearchParams] = useSearchParams();
	const [isValid, setIsValid] = useState(true);
	const { data, isFetching } = useQuery({
		queryKey: ['bili', searchParams.get('q')],
		queryFn: async ({ queryKey }) => {
			const res = await fetch(
				'https://i.ihook.center/api/bili?q=' + queryKey[1],
			);

			const ans = await res.text();
			return ans;
		},
		enabled: searchParams.get('q') !== '',
		refetchOnWindowFocus: false,
	});

	useMount(() => {
		document.title = 'bilibili 视频转文稿';
	});

	return (
		<div className={style.container}>
			<div className={style.content}>
				<div className={style.title}>bilibili 视频转文稿</div>
				<Input
					className={style.input}
					label="bili text"
					value={searchParams.get('q')}
					onChange={async (e) => {
						const url = e.target.value;
						if (!checkBili(url)) {
							setIsValid(false);
							setSearchParams({ q: '' });
							return;
						}

						setIsValid(true);
						setSearchParams({ q: url });
					}}
				/>
				{searchParams.get('q') && <a href={searchParams.get('q')}>来源</a>}
				<Spin spinning={isFetching}>
					<pre>{data}</pre>
				</Spin>

				{isValid === false && <div>地址校验不正确</div>}
			</div>
		</div>
	);
}

function checkBili(url: string) {
	return url?.indexOf('https://www.bilibili.com') === 0;
}
